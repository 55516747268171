import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        minWidth: '400px',
        borderBottom: '1px solid #80808057',
        padding: '10px 24px',
        fontWeight: 500
    },
    dialogButton: {
        backgroundColor: "#F50057 !important",
        color: "#fff !important"
    }
}))

const AlertMessage = ({
    open,
    close,
    message,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogButton} onClick={close} color="secondary" style={{ textTransform: 'none' }}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertMessage
