import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import configurations from "../../../configurations";
import moment from 'moment';
import Loader from "../../Commonscreens/loader";
import AlertDialog from '../../Commonscreens/AlertDialog'
import PaymentFormFields from './PaymentFormFields';
import RefundPayment from './RefundPayment';
import { getTransactionIdValue } from '../../Services/CommonServices';
import PaymentConfirmation from './ConfirmBox';
import TransactionHistoryModal from './TransactionHistoryModal'
import ChangePayment from '../changePayment/Transaction';
import AlertMessage from '../AlertMessage';

const useStyles = makeStyles(theme => ({

    root: {
        // width: '75%'
        // marginTop: '30px',
        padding: '10px 0px',
        background: '#fff'
    },
    formControl: {
        // width: '100%'
        // marginTop: '20px'
    },
    paymentChoose: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-around'

    },
    label: {
        // flex: 1
        margin: 0,
        minWidth: '175px',
        background: '#d4d4d463',
        height: '50px',
        paddingRight: '10px'
    },
    labelActive: {
        background: 'rgb(51, 175, 176)',
        color: 'white'
    },

    footer: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center'
    }

}));

export const patterns = {
    text: /^[a-zA-Z]*$/,
    num: /^[0-9]*$/,
    name: /^[a-zA-Z ]{1,120}$/,
    accountNumber: /^[0-9]{8,17}$/,
    routingNo: /^[0-9]{9}$/,
    cardNumber: /^[0-9]{16}$/,
    cvv: /^[0-9]{3,4}$/,
    // amount: /^[1-9]+(\.\d{1,2})?$/
    // amount:/^0*[1-9]\d+(\.\d{1,2})?$/
    amount: /^0*[1-9]\d*(\.\d{1,2})?$/
    // amount:/^0*[1-9]\d*$/

}
const PaymentModes = {
    AccountOnFile: 'AccountOnFile', Card: 'Card', ACH: 'ACH', Transaction: 'Transaction', Refund: 'Refund', RefundAdjust: 'RefundAdjust'
}
const _achFields = [{ label: 'Bank Name', value: '', key: 'bankName', pattern: patterns.name },
{ label: 'Account Name', value: '', key: 'accountName', pattern: patterns.name }, { label: 'Routing Number', value: '', key: 'routingNo', pattern: patterns.routingNo }, { label: 'Account Number', value: '', key: 'accountNumber', pattern: patterns.accountNumber }, {
    label: 'Account Type', type: 'DropDown',
    fields: [
        { label: 'SAVINGS', isSelected: false, value: 'SAVINGS' },
        { label: 'CHECKING', isSelected: false, value: 'CHECKING' }
    ],
    value: '', key: 'accountType'
},
// { label: 'Amount $', value: '', key: 'amount', pattern: patterns.amount }, 
{ label: 'Description', value: '', key: 'description' }];

const _cardFields = [{ label: 'Card Number', value: '', key: 'cardNumber', pattern: patterns.cardNumber }, { label: 'Card Holder Name', value: '', key: 'holderName', pattern: patterns.name }, {
    label: 'Expiration month', key: 'expiryMonth', type: 'DropDown',
    fields: [
        { label: 'Jan', isSelected: false, value: '1' },
        { label: 'Feb', isSelected: false, value: '2' },
        { label: 'Mar', isSelected: false, value: '3' },
        { label: 'Apr', isSelected: false, value: '4' },
        { label: 'May', isSelected: false, value: '5' },
        { label: 'Jun', isSelected: false, value: '6' },
        { label: 'Jul', isSelected: false, value: '7' },
        { label: 'Aug', isSelected: false, value: '8' },
        { label: 'Sep', isSelected: false, value: '9' },
        { label: 'Oct', isSelected: false, value: '10' },
        { label: 'Nov', isSelected: false, value: '11' },
        { label: 'Dec', isSelected: false, value: '12' }],
    value: ''
}, {
    label: 'Expiration Year*', key: 'expiryYear', type: 'DropDown',
    fields: [
        { label: '2020', isSelected: false, value: '2020' },
        { label: '2021', isSelected: false, value: '2021' },
        { label: '2022', isSelected: false, value: '2022' },
        { label: '2023', isSelected: false, value: '2023' },
        { label: '2024', isSelected: false, value: '2024' },
        { label: '2025', isSelected: false, value: '2025' },
        { label: '2026', isSelected: false, value: '2026' },
        { label: '2027', isSelected: false, value: '2027' },
        { label: '2028', isSelected: false, value: '2028' },
        { label: '2029', isSelected: false, value: '2029' },
        { label: '2030', isSelected: false, value: '2030' }
    ], value: ''
}, { label: 'CCV', value: '', key: 'cvv', pattern: patterns.cvv },
// { label: 'Amount $ (Not inclusive of merchant fees)', value: '', key: 'amount', pattern: patterns.amount, helperText: "Amount $" },
{ label: 'Description', value: '', key: 'description' }];

const _transactionFields = [{ label: 'Transaction ID', value: '', key: 'transactionId', pattern: patterns.num },
// { label: 'Amount $', value: '', key: 'amount', pattern: patterns.amount },
{ label: 'Description', value: '', key: 'description' }]

const _refundFields = [{ label: 'Transaction ID', value: '', key: 'transactionId', pattern: patterns.num, type: 'TextWithBtn', }, { label: 'Refund Amount', value: '', key: 'amount', pattern: patterns.amount, type: 'ReadOnly' }, { label: 'Description', value: '', key: 'description' }];

const _useAccountFields = [{ label: 'Account Number', value: '', key: 'fileNo', type: 'ReadOnly' }, { label: 'Description', value: '', key: 'description' }]


export const errorMessage = "We did not get the expected response from payment gateway. Please check gateway transaction report. Do not attempt to execute any transactions - please report this problem to administrator.";

const CreatePayment = (props) => {
    const classes = useStyles();

    const [selectedPaymentMode, setpaymentMode] = useState(PaymentModes.AccountOnFile)
    const [achFields, setachFields] = useState(_achFields);
    const [cardFields, setCardfields] = useState(_cardFields);
    const [trasnFields, setTransFields] = useState(_transactionFields);
    const [refundFields, setRefundFields] = useState(_refundFields);
    const [accountOnFileFields, setAccountOnFileFields] = useState(_useAccountFields);
    const [showEmpDetails, toggleShowEmpDetails] = useState(false);
    const [disableAll, setDisableAll] = useState(true);
    const [memberDetails, setMemberDetails] = useState(false);
    const [empId, setEmpId] = useState('');
    const [showLoader, toggleLoader] = useState(false);
    const [empIdNotValid, toggleEmpIdValid] = useState(false);
    const [showAlert, setAlertValue] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [showConfirm, toggleConfrm] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState('');
    const [paymentRequest, setPaymentRequest] = useState({ amount: '' });
    const [showTransHistModal, toggleTransHisModal] = useState(false);

    const [showChangePaymentModal, toggleChangePaymntModal] = useState(false);
    const [showAmountBreakup, toggleAmountBreakup] = useState(false);
    const [amountBreakup, setAmountBreakup] = useState(false);
    const [cleared, setCleared] = useState(false);
    const [showEligibilityMessage, setShowEligibilityMessage] = useState(false);
    const [eligibilityMessage, setEligibilityMessage] = useState("");

    useEffect(() => {
        if (cleared) {
            setCleared(false)
        }
    }, [cleared])

    useEffect(() => {
        clearAllFields(true)
    }, [])


    const onPaymentModeChange = (event) => {
        let mode = event.target.value
        setpaymentMode(mode);
    }

    const toggleAlert = (value) => {
        setAlertValue(false);
        setTimeout(() => {
            setAlertValue(value);
        }, 10);
    }

    const setValuesInArr = (arr, value, controlIndx) => {
        arr[controlIndx].value = value;
        if (arr[controlIndx].pattern) {
            let isPatternMatched = value.match(arr[controlIndx].pattern);
            arr[controlIndx]['hasError'] = !isPatternMatched;
        }
        return arr;
    }

    const changeValue = (value, controlIndx) => {
        console.log('change value::')
        if (selectedPaymentMode == PaymentModes.AccountOnFile) {
            let _accountOnFileFields = accountOnFileFields;
            _accountOnFileFields = setValuesInArr(_accountOnFileFields, value, controlIndx);
            setAccountOnFileFields([..._accountOnFileFields]);

        }
        else if (selectedPaymentMode == PaymentModes.ACH) {
            let _achFields = achFields;
            _achFields = setValuesInArr(_achFields, value, controlIndx);
            setachFields([..._achFields]);

        } else if (selectedPaymentMode == PaymentModes.Card) {
            let _cardFields = cardFields;
            _cardFields = setValuesInArr(_cardFields, value, controlIndx);
            setCardfields([..._cardFields])
        }
        else if (selectedPaymentMode == PaymentModes.Transaction) {
            let _trasnFields = trasnFields;
            if (_trasnFields[controlIndx].key == 'transactionId') {
                value = getTransactionIdValue(value);
            }
            _trasnFields = setValuesInArr(_trasnFields, value, controlIndx);
            setTransFields([..._trasnFields]);

        } else if (selectedPaymentMode == PaymentModes.Refund) {
            let _refundFields = refundFields;
            _refundFields = setValuesInArr(_refundFields, value, controlIndx);
            setRefundFields([..._refundFields]);

        }
    }

    const getFields = () => {

        switch (selectedPaymentMode) {
            case PaymentModes.AccountOnFile:
                return accountOnFileFields;
            case PaymentModes.ACH:
                return achFields;
            case PaymentModes.Card:
                return cardFields;
            case PaymentModes.Transaction:
                return trasnFields;
            case PaymentModes.Refund:
                return refundFields;
            default:
                return [];
        }

    }

    // const login = async () => {
    //     let token = null
    //     let request = {
    //     "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
    //     "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
    // }
    //     toggleLoader(true);
    //     await axios.post(configurations.csrBaseUrl + 'login', request).then((resp) => {
    //         if (resp.headers.authorization) {
    //             token = resp.headers.authorization
    //             sessionStorage.setItem('csrtoken', token)
    //         }
    //     }).catch((err) => {
    //         toggleLoader(false);
    //     });
    //     return token

    // }

    const loginWithPromise = () => {
        return new Promise((resolve, reject) => {
            let token = null
            let request = {
                "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
                "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
            }
            setTimeout(() => {
                toggleLoader(true);
                axios.post(configurations.csrBaseUrl + 'login', request).then((resp) => {
                    if (resp.headers.authorization) {
                        token = resp.headers.authorization
                        sessionStorage.setItem('csrtoken', token)
                        resolve(token)
                    }
                }).catch((err) => {
                    reject(err)
                    toggleLoader(false);
                });
            }, 100);

        })
    }


    const getAccountNumber = (memberIdSource) => {

        toggleLoader(true);
        axios.get(configurations.paymentBaseUrl + 'transaction/getLast4AccountNumber/' + memberIdSource).then((resp) => {
            if (resp.data.response !== null) {
                let accountNo = resp.data.response.replaceAll("*", "X")
                let _accountOnFileFields = [...accountOnFileFields];
                _accountOnFileFields[0].value = accountNo;

                setAccountOnFileFields(_accountOnFileFields);
            } else {
                setDisableAll(true);
                setAlertMsg(resp.data.message)
                toggleAlert(true)
            }
            toggleLoader(false);


        }).catch((err) => {
            toggleLoader(false);
        });

    }

    const getPaymentAmount = (memberIdSource) => {

        toggleLoader(true);
        axios.get(configurations.paymentBaseUrl + 'adminportal/getMemberAmount/' + memberIdSource).then((resp) => {
            console.log(resp.data)
            if (resp.data.response) {
                setAmountBreakup(resp.data.response);
                toggleAmountBreakup(true)
            } else {

                setAmountBreakup(false);
                toggleAmountBreakup(false)
            }
            toggleLoader(false);


        }).catch((err) => {
            toggleLoader(false);
            setAmountBreakup(false);
            toggleAmountBreakup(false)

        });

    }

    const getEMPDetails = () => {

        let empWithoutStrings = empId.replace(/\D/g, "");
        let netwellOrganization = Buffer.from(`NETWELL;${new Date()}`).toString('base64')
        clearAllFields(true);
        toggleAmountBreakup(false)
        if (empWithoutStrings && empWithoutStrings.length > 0) {
            toggleEmpIdValid(false)
            loginWithPromise()
                .then((token) => {
                    console.log('token::', token)
                    // setMemberDetails(false);
                    // setDisableAll(true);
                    let request = {
                        "empId": empWithoutStrings
                    }
                    axios.post(configurations.csrBaseUrl + 'csrportal/empid/fetch', request, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('NetwellbearerToken'),
                            'X-Tenant-Id': netwellOrganization
                        }
                    }).then((resp) => {
                        console.log(resp.data)
                        toggleLoader(false);
                        if (resp.data) {
                            if (resp.data.subscriberIndicator == 'Y') {
                                setMemberDetails(resp.data)
                                toggleShowEmpDetails(true);
                                setDisableAll(false);
                                localStorage.setItem('memberIdSource', resp.data.memberIdSource)
                                getPaymentAmount(resp.data.memberIdSource)
                                getAccountNumber(resp.data.memberIdSource)
                            } else if ('N') {
                                setAlertMsg('The member is not Primary.')
                                toggleAlert(true)
                            } else {
                                setAlertMsg(errorMessage)
                                toggleAlert(true)
                            }

                        } else {
                            setAlertMsg(errorMessage)
                        }

                    }).catch((err) => {
                        console.log(err)
                        setMemberDetails(false);
                        toggleShowEmpDetails(false);
                        setDisableAll(true);
                        toggleLoader(false);
                        toggleAlert(true)
                        if (err.response.data && err.response.data.message) {

                            setAlertMsg(err.response.data.message)

                        } else {
                            setAlertMsg(errorMessage)
                        }

                    });;
                });
        } else {
            // toggleEmpIdValid(true);
            setMemberDetails(false);
            toggleShowEmpDetails(false);
            setDisableAll(true);
        }
    }

    const makePaymentClick = (amountBreakup, total, totalAmountAll, _updatedAmountBreakup) => {
        axios.get(`${process.env.REACT_APP_paymentBaseUrl}adminportal/paymentEligibility/${memberDetails.memberIdSource}`)
        .then(res => {
            if (res.data.code == 200) {
                setShowEligibilityMessage(false);
                // if (totalAmountAll <= 0) {
        //     setAlertMsg('Please select a valid amount.')
        //     toggleAlert(true)
        //     return false
        // }
        let isAllFieldsValid = checkFieldsValid();

        if (isAllFieldsValid) {
            let request = {};
            let loggedinUserEmail = localStorage.getItem('email');
            if (selectedPaymentMode == PaymentModes.AccountOnFile) {
                request = {
                    source: memberDetails.memberIdSource,
                    amount: totalAmountAll,
                    displayAmount: totalAmountAll,
                    description: getValuesByKey('description', accountOnFileFields),
                    applicationFee: _updatedAmountBreakup.applicationFee,
                    uhfMonthlyFee: _updatedAmountBreakup.uhfMonthlyFee,
                    monthlyShare: _updatedAmountBreakup.monthlyShare,
                    dentalAmount: _updatedAmountBreakup.dentalAmount,
                    admin: loggedinUserEmail,
                    merchantFee: _updatedAmountBreakup.cardmerchantFee ? _updatedAmountBreakup.cardmerchantFee : null,
                }

                if (_updatedAmountBreakup.addOns && _updatedAmountBreakup.addOns.length > 0) {
                    _updatedAmountBreakup.addOns.forEach((addOn) => {
                        if (addOn.checked) {
                            request[addOn.key] = addOn.amount
                        }
                    })
                }


            }
            else if (selectedPaymentMode == PaymentModes.ACH) {
                // request = {
                //     bankName: getValuesByKey('bankName', achFields),
                //     accountName: getValuesByKey('accountName', achFields),
                //     accountNumber: getValuesByKey('accountNumber', achFields),
                //     accountType: getValuesByKey('accountType', achFields),
                //     routingNo: getValuesByKey('routingNo', achFields),
                //     amount: total,
                //     displayAmount: totalAmountAll,
                //     description: getValuesByKey('description', achFields),
                //     paymentType: 'ACH',
                //     applicationFee: amountBreakup.applicationFee,
                //     uhfMonthlyFee: amountBreakup.uhfMonthlyFee,
                //     monthlyShare: amountBreakup.monthlyShare,
                //     admin: loggedinUserEmail,
                // }

            } else if (selectedPaymentMode == PaymentModes.Card) {
                // request = {
                //     cardNumber: getValuesByKey('cardNumber', cardFields),
                //     holderName: getValuesByKey('holderName', cardFields),
                //     expiryMonth: getValuesByKey('expiryMonth', cardFields),
                //     expiryYear: getValuesByKey('expiryYear', cardFields),
                //     cvv: getValuesByKey('cvv', cardFields),
                //     amount: totalAmountAll, //for credit card - added merchant fee in UI
                //     displayAmount: totalAmountAll,
                //     description: getValuesByKey('description', cardFields),
                //     applicationFee: amountBreakup.applicationFee,
                //     uhfMonthlyFee: amountBreakup.uhfMonthlyFee,
                //     monthlyShare: amountBreakup.monthlyShare,
                //     merchantFee: cardmerchantFee,
                //     paymentType: 'CC',
                //     admin: loggedinUserEmail,
                // }

            } else if (selectedPaymentMode == PaymentModes.Transaction) {
                // request = {
                //     transactionId: getValuesByKey('transactionId', trasnFields),
                //     amount: totalAmountAll,//for credit card - added merchant fee in UI
                //     displayAmount: totalAmountAll,
                //     admin: loggedinUserEmail,
                //     description: getValuesByKey('description', trasnFields),
                //     applicationFee: amountBreakup.applicationFee,
                //     uhfMonthlyFee: amountBreakup.uhfMonthlyFee,
                //     monthlyShare: amountBreakup.monthlyShare,
                //     merchantFee: cardmerchantFee,
                // }

            }
            if (selectedPaymentMode == PaymentModes.Transaction || selectedPaymentMode == PaymentModes.AccountOnFile) {
                setPaymentRequest(request);
                toggleConfrm(true);
            } else {
                checkPaymentInfo(request)
            }


        } else {
            setAlertMsg('Please enter valid values.');
            toggleAlert(true)
        }
            } else {
                setShowEligibilityMessage(true);
                setEligibilityMessage(res.data.message);
            }
        })
            .catch(err => {
                setShowEligibilityMessage(true);
                setEligibilityMessage('Please try again!');
            })
    }

    const checkPaymentInfo = (paymentDetails) => {

        let request = {}
        if (selectedPaymentMode == PaymentModes.ACH) {
            request = {
                "source": memberDetails.memberIdSource,
                "bankName": paymentDetails.bankName,
                "accountName": paymentDetails.accountName,
                "accountNumber": paymentDetails.accountNumber,
                "accountType": paymentDetails.accountType,
                "routingNo": paymentDetails.routingNo,
                "paymentType": paymentDetails.paymentType
            }
        } else if (selectedPaymentMode == PaymentModes.Card) {
            request = {
                "source": memberDetails.memberIdSource,
                "cardNumber": paymentDetails.cardNumber,
                "holderName": paymentDetails.holderName,
                "expiryMonth": paymentDetails.expiryMonth,
                "expiryYear": paymentDetails.expiryYear,
                "cvv": paymentDetails.cvv,
                "paymentType": paymentDetails.paymentType
            }
        }
        toggleLoader(true);
        axios.post(configurations.paymentBaseUrl + 'transaction/storeTransaction', request).then((resp) => {
            if (resp.data.response) {
                let response = resp.data.response;
                let responseObj = {}
                try {
                    responseObj = JSON.parse(response);
                } catch (err) {
                    console.log(err)
                }
                finally { }
                if (responseObj && responseObj.result && responseObj.result.authorization_message) {
                    setPaymentRequest(paymentDetails);
                    toggleConfrm(true);
                } else if (responseObj && responseObj.error_message) {

                    setAlertMsg(responseObj.error_message);
                    toggleAlert(true);
                }
            }
            toggleLoader(false);

        }).catch((err) => {
            console.log(err)
            setAlertMsg('Some thing went wrong! Please contact the administrator.');
            toggleAlert(true);
            toggleLoader(false);
        })
    }

    const closeConfirm = () => {
        toggleConfrm(false)
    }

    const accept = () => {
        toggleConfrm(false);
        makePayment(paymentRequest)
    }
    const makePayment = (request) => {
        let amount = request.amount;

        let source = memberDetails.memberIdSource;
        // source = '1597404647520';
        toggleLoader(true);
        let apiURL = configurations.paymentBaseUrl + 'adminportal/makePayment/' + source;
        if (selectedPaymentMode == PaymentModes.Transaction) {
            apiURL = configurations.paymentBaseUrl + 'adminportal/paymentByTransactionID/' + source
        } else if (selectedPaymentMode == PaymentModes.AccountOnFile) {
            apiURL = configurations.paymentBaseUrl + 'adminportal/paymentBySource'
        }

        request.displayAmount = null;

        axios.post(apiURL, request).then((resp) => {
            console.log(resp.data)
            if (resp.data.code == 200) {
                setAlertMsg(resp.data.message);
                toggleAlert(true);
                clearAllFields()
            } else {
                try {
                    if (resp.data.message) {
                        let data = JSON.parse(resp.data.message);
                        if (data) {
                            setAlertMsg(data.error_message)
                        }
                    } else if (resp.data.response) {
                        let data = JSON.parse(resp.data.response);
                        if (data) {
                            setAlertMsg(data.error_message)
                        }
                    } else {
                        setAlertMsg(errorMessage)
                    }



                    toggleAlert(true)
                } catch (err) {
                    if (resp.data.message) {
                        setAlertMsg(resp.data.message);
                    } else {
                        setAlertMsg(errorMessage)
                    }
                    // toggleLoader(false);
                    toggleAlert(true)
                }
            }
            toggleLoader(false);


        }).catch((err) => {
            console.log(err)
            if (err.response && err.response.data && err.response.data.message) {
                setAlertMsg(err.response.data.message)
            } else {
                setAlertMsg(errorMessage)
            }
            toggleAlert(true);
            toggleLoader(false);
        });;
    }



    const getValuesByKey = (key, arr) => {
        let value = ''
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].key == key) {
                value = arr[i].value;
                break;
            }
        }
        return value;
    }


    const checkFieldsValid = () => {
        let allFieldsValid = true;
        if (selectedPaymentMode == PaymentModes.ACH) {
            accountOnFileFields.forEach((item) => {
                if (item.hasError) {
                    allFieldsValid = false
                }
            })
        }
        // else if (selectedPaymentMode == PaymentModes.ACH) {
        //     achFields.forEach((item) => {
        //         if (item.hasError) {
        //             allFieldsValid = false
        //         }
        //     })
        // } else if (selectedPaymentMode == PaymentModes.Card) {
        //     cardFields.forEach((item) => {
        //         if (item.hasError) {
        //             allFieldsValid = false
        //         }
        //     });
        // } else if (selectedPaymentMode == PaymentModes.Transaction) {
        //     trasnFields.forEach((item) => {
        //         if (item.hasError) {
        //             allFieldsValid = false
        //         }
        //     });
        // }
        return allFieldsValid;
    }

    const clearAllFields = (_forceAll) => {
        // let _achFields = clearValues(achFields);
        // let _cardFields = clearValues(cardFields);
        // let _trasnFields = clearValues(trasnFields);
        let _accountOnFileFields = clearValues(accountOnFileFields, _forceAll);

        // setachFields(_achFields);
        // setCardfields(_cardFields);
        // setTransFields(_trasnFields);
        setAccountOnFileFields(_accountOnFileFields);
        setCleared(true)


    }

    const clearValues = (arr, _forceAll) => {
        let tempArr = [...arr]
        for (let i = 0; i < tempArr.length; i++) {
            if (tempArr[i].type != 'ReadOnly') {
                tempArr[i]['value'] = '';
            }
            if (_forceAll) {
                tempArr[i]['value'] = '';
            }
            tempArr[i]['hasError'] = false;
        }
        // debugger
        return tempArr;
    }

    const getSection = () => {
        if (cleared) {
            return null;
        } else {
            switch (selectedPaymentMode) {
                case PaymentModes.AccountOnFile:
                case PaymentModes.ACH:
                case PaymentModes.Card:
                case PaymentModes.Transaction:
                    return <PaymentFormFields changeValue={changeValue} fields={getFields()} disableAll={disableAll} {...props} makePaymentClick={makePaymentClick} clearAllFields={clearAllFields} showAmountBreakup={showAmountBreakup} amountBreakup={amountBreakup} selectedPaymentMode={selectedPaymentMode} toggleLoader={toggleLoader} />
                case PaymentModes.Refund:
                case PaymentModes.RefundAdjust:
                    return <RefundPayment {...props} toggleLoader={toggleLoader} toggleAlert={toggleAlert} setAlertMsg={setAlertMsg} disableAll={disableAll} memberDetails={memberDetails} login={loginWithPromise} selectedPaymentMode={selectedPaymentMode} />
                default:
                    return null;
            }
        }

    }

    const empIdOnBlur = (e) => {
        let empIDLength = 0;
        let value = e.target.value;
        console.log('enter empIdOnBlur')
        // console.log(value)
        if (value.length >= empIDLength) {
            getEMPDetails()
        }
    }

    const toggleAlertBox = (message, showAlert) => {
        // props.toggleAlertBox(response.data.message, true, 'Alert')
        setAlertMsg(message)
        toggleAlert(showAlert);
    }

    return (
        <div style={{ width: '100%', overFlow: 'hidden' }}>
            {
                showLoader && <Loader />
            }
            <div className={classes.root} style={{ minHeight: '89vh', marginTop: 10 }}>
                {/* <div>
                    Create Payment
                    </div> */}

                <div style={{
                    textAlign: 'left',
                    paddingLeft: '5%',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div>

                        <TextField variant="filled" style={{ width: '275px%' }} label='Enter Member ID' value={empId} onChange={(e) => { setEmpId(e.target.value.trim()) }} required={false} helperText={empIdNotValid ? 'Enter valid Member ID' : ''} error={empIdNotValid} onBlur={empIdOnBlur} onKeyPress={(e) => {
                            if (e.key == 'Enter') {
                                console.log('enter pressed')
                                e.target.blur()
                                // getEMPDetails()
                            }
                        }} />
                        {/* <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            style={{
                                marginLeft: '15px',
                                marginTop: '20px',
                                textTransform: 'none',
                                height: '35px'
                            }}
                            // startIcon={<MailOutlineIcon />}
                            onClick={getEMPDetails}
                        // disabled={disableSaveBtn}
                        >Submit</Button> */}
                    </div>

                    <div style={{
                        marginRight: '50px',
                        padding: "10px",
                        background: '#f3e1e1',
                        minWidth: '400px',
                        display: showEmpDetails && memberDetails ? 'block' : 'none'
                    }}>
                        <p style={{ margin: 0, fontWeight: 'bold' }}>
                            <span style={{ fontWeight: 'normal' }}>Name: </span>{`${memberDetails.prefix || ""} ${memberDetails.firstName} ${memberDetails.middleName || ""} ${memberDetails.lastName} ${memberDetails.suffix || ""}`}
                        </p>
                        <p style={{ margin: 0, fontWeight: 'bold' }}>
                            <span style={{ fontWeight: 'normal' }}>DOB: </span> {moment(memberDetails.dateOfBirth).format('MM/DD/YYYY')}
                        </p>
                        <p style={{ margin: 0, fontWeight: 'bold' }}>
                            <span style={{ fontWeight: 'normal' }}>Email: </span>  {memberDetails.email}
                        </p>
                        <div style={{
                            marginTop: '5px'
                        }}>

                            <Button variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    // textDecoration: 'underline',
                                    // color: '#303f9f',
                                    olor: 'white',
                                    padding: '3px 10px'
                                }} onClick={() => toggleChangePaymntModal(true)}>Change Payment Method</Button>

                            <Button style={{
                                textTransform: 'none',
                                textDecoration: 'underline',
                                color: '#303f9f'
                            }} onClick={() => toggleTransHisModal(true)}>View transaction history</Button>
                        </div>


                    </div>
                </div>
                {/* <Grid container spacing={3}>
                    <Grid item xs={6}>


                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid> */}
                <div style={{
                    textAlign: 'left',
                    paddingLeft: '5%',
                    marginTop: '20px'
                }}>
                    <FormControl component="fieldset" className={classes.formControl} >
                        <RadioGroup aria-label="Payment mode" name="paymentmode" onChange={onPaymentModeChange} className={classes.paymentChoose} value={selectedPaymentMode} >
                            {/* <FormControlLabel className={[classes.label, selectedPaymentMode == PaymentModes.Card ? classes.labelActive : '']} value={PaymentModes.Card} control={<Radio />} label="Credit/Debit Card" />
                            <FormControlLabel className={[classes.label, selectedPaymentMode == PaymentModes.ACH ? classes.labelActive : '']} value={PaymentModes.ACH} control={<Radio />} label="ACH Debit" /> */}
                            <FormControlLabel className={[classes.label, selectedPaymentMode == PaymentModes.AccountOnFile ? classes.labelActive : '']} value={PaymentModes.AccountOnFile} control={<Radio />} label="Use Account on File" />

                            {/* <FormControlLabel className={[classes.label, selectedPaymentMode == PaymentModes.Transaction ? classes.labelActive : '']} value={PaymentModes.Transaction} control={<Radio />} label="Use Transaction ID" /> */}
                            <FormControlLabel className={[classes.label, selectedPaymentMode == PaymentModes.Refund ? classes.labelActive : '']} value={PaymentModes.Refund} control={<Radio />} label="Refund" />
                            <FormControlLabel className={[classes.label, selectedPaymentMode == PaymentModes.RefundAdjust ? classes.labelActive : '']} value={PaymentModes.RefundAdjust} control={<Radio />} label="Chargeback/Refund/ACH Return Adjust" />
                        </RadioGroup>
                    </FormControl>

                </div>


                <div style={{ marginTop: '20px' }}>
                    {
                        getSection()
                    }

                </div>


            </div>

            <AlertDialog open={showAlert} messageText={alertMsg} closeAlert={() => toggleAlert(false)} />
            <PaymentConfirmation open={showConfirm} handleClose={closeConfirm} paymentRequest={paymentRequest} memberDetails={memberDetails} accept={accept} />

            {
                showTransHistModal &&
                <TransactionHistoryModal open={showTransHistModal} handleClose={() => toggleTransHisModal(false)} toggleLoader={toggleLoader} memberDetails={memberDetails} toggleAlertBox={toggleAlertBox} />
            }
            {
                showChangePaymentModal &&
                <ChangePayment open={showChangePaymentModal} source={memberDetails.memberIdSource} closeModal={() => toggleChangePaymntModal(false)} toggleLoader={toggleLoader} toggleAlertBox={toggleAlertBox} getEMPDetails={getEMPDetails} />
            }
            <AlertMessage open={showEligibilityMessage} close={() => setShowEligibilityMessage(false)} message={eligibilityMessage} />
        </div >

    )
}

export default CreatePayment;
